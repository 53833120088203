import "./App.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { Home } from "./pages/Home";
import { BaseLayout } from "./components/layout/BaseLayout";
import { WalletPage } from "./pages/WalletPage";

function App() {
  return (
    <div className="App" data-theme="onmetaverse">
      <Router>
        <BaseLayout>
          <Routes>
            <Route path="/" element={<Navigate to="/web3academy" />} />
            <Route path="/web3academy" element={<Home />} />
            <Route path="/web3academy/wallet" element={<WalletPage />} />
          </Routes>
        </BaseLayout>
      </Router>
    </div>
  );
}

export default App;
