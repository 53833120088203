import { useCallback, useEffect, useState } from "react";
import { useAccount, useContract, useContractRead } from "wagmi";
import { getAlchemyProvider, provider } from "../utils/blockchain";
import { Banner } from "./Banner";
import { Section } from "./layout/Section";
import Abi2048 from "../abi/2048_abi.json";
import { TransactionStatus, useMintAndWait } from "../utils/api";
import { Loader } from "./Loader";
import { getImageURL } from "../utils/ipfs";
import { useMount, usePrevious } from "react-use";
import { OpenseaLogo } from "./icons";
import { ethers } from "ethers";
import { ipfsLinkBuilder } from "../utils/common";

interface TabProps {
  active: boolean;
  contractAddress: string;
  right?: string;
  proof?: string[];
  leaf?: string;
  signature?: string;
  address?: string;
  type: "onMetaverse" | "2048";
}

export const Tab: React.FC<TabProps> = ({
  contractAddress,
  right,
  active,
  proof,
  signature,
  address,
  type,
}) => {
  const [ids, setIds] = useState<Array<number>>([]);
  const [ipfsLinks, setIpfsLinks] = useState<Array<string>>([]);
  const [jsonUrls, setJsonUrls] = useState<Array<string>>([]);
  const [metadata, setMetadata] = useState<Array<any>>([]);
  const [imageUrls, setImageUrls] = useState<Array<string>>([]);
  const [{ data: redeemed }, getRedeemed] = useContractRead(
    {
      addressOrName: contractAddress,
      contractInterface: Abi2048,
      signerOrProvider: getAlchemyProvider(),
    },
    "redeemed",
    { args: right, skip: true }
  );

  const contract = useContract({
    addressOrName: contractAddress,
    contractInterface: Abi2048,
    signerOrProvider: getAlchemyProvider(),
  });

  const [{ data: accountData }] = useAccount({
    fetchEns: true,
  });

  const [{ data: tokenOfOwner, loading }, getTokenOfOwner] = useContractRead(
    {
      addressOrName: contractAddress,
      contractInterface: Abi2048,
      signerOrProvider: getAlchemyProvider(),
    },
    "tokenOfOwnerByIndex",
    { args: [address, 0], skip: true }
  );

  useEffect(() => {
    if (!right) return;

    getRedeemed();
  }, [right]);

  const tokenId = tokenOfOwner?.toNumber();

  useMount(() => {
    getTokenOfOwner();
    getIds();
  });

  const { mintAndWait, isLoading, status } = useMintAndWait({
    ref: right,
    signature,
    address,
    proof,
    cnt_addr: contractAddress,
  });

  const prevStatus = usePrevious(status);

  useEffect(() => {
    if (!address || !redeemed || tokenId || loading) return;
    if (status !== TransactionStatus.STATUS_EXECUTED || prevStatus === status)
      return;

    getTokenOfOwner();
  }, [
    status,
    prevStatus,
    address,
    redeemed,
    tokenId,
    loading,
    getTokenOfOwner,
  ]);

  const handleClaim = useCallback(async () => {
    console.log(right);
    console.log(address);
    console.log(signature);
    console.log(proof);

    if (!right || !address || !signature || !proof) return;

    mintAndWait()
      .then(() => getIds())
      .catch((err) => console.log(err));
  }, [right, address, signature, proof, mintAndWait]);

  // Parte matteo
  const getIds = async () => {
    const eventFilter = contract.filters.Transfer(
      ethers.constants.AddressZero,
      accountData?.address,
      null
    );

    const allEvents = await contract.queryFilter(eventFilter, 10551062);
    const lastEvents = allEvents.slice(-1);
    const ids = lastEvents.map((el: any) => {
      if (el.args) {
        return el.args[2].toNumber();
      } else {
        return 0;
      }
    });

    setIds(ids.reverse());

    const promises = ids.map(async (id: number) => contract.tokenURI(id));

    Promise.all(promises).then((result) => setIpfsLinks(result));
  };

  useEffect(() => {
    if (ipfsLinks.length > 0) {
      setJsonUrls(ipfsLinks.map((link) => ipfsLinkBuilder(link)));
    }
  }, [ipfsLinks]);

  const getMetadata = async () => {
    const metadatas: Array<any> = [];
    const promise = await jsonUrls.map(async (json) => {
      await fetch(json)
        .then((res) => res.json())
        .then((res) => {
          metadatas.push(res);
        });
    });

    Promise.all(promise).then(() => setMetadata(metadatas));
  };

  useEffect(() => {
    if (jsonUrls.length > 0) {
      getMetadata();
    }
  }, [jsonUrls]);

  useEffect(() => {
    if (metadata.length > 0) {
      const ipfsImages = metadata.map((meta) => meta.image);
      const images = ipfsImages.map(ipfsLinkBuilder);

      setImageUrls(images);
    }
  }, [metadata]);

  const getImagesSorted = () => {
    if (imageUrls.length === 0) return [];

    const baseUrl = imageUrls[0].split("/").slice(0, -1).join("/");

    return ids.map((id) => `${baseUrl}/${id}.png`);
  };

  if (!active) return null;

  return (
    <>
      {redeemed && !tokenId && (
        <Section>
          <Banner>
            Questo QR code è già stato usato per per questa sezione
          </Banner>
        </Section>
      )}
      {!status && !tokenId && (
        <button
          className="btn btn-primary"
          onClick={handleClaim}
          disabled={redeemed === undefined || Boolean(redeemed)}
        >
          Richiedi NFT
        </button>
      )}

      {getImagesSorted().length > 0 && (
        <Section>
          <div className="flex justify-center align-center">
            <img
              src={getImagesSorted()[0]}
              alt="NFT"
              style={{ width: 250, height: 250 }}
            />
          </div>
        </Section>
      )}

      {tokenId && type === "2048" && (
        <Section>
          <div className="flex justify-center align-center">
            <a
              href="https://playground.nft-factory.club"
              target="_blank"
              rel="noopener noreferrer"
              className="max-w-xs btn btn-primary"
            >
              Prova subito il gioco 😉
            </a>
          </div>
        </Section>
      )}

      {tokenId && (
        <Section>
          <div className="flex justify-center align-center">
            <a
              href={`https://opensea.io/assets/${contractAddress}/${tokenId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="max-w-xs btn btn-primary"
            >
              Guarda su opensea
            </a>
          </div>
        </Section>
      )}

      {isLoading && <Loader />}
    </>
  );
};
