import { useMemo, useState } from "react";
import { useInterval } from "react-use";

export const mint = (
  ref: string,
  address: string,
  signature: string,
  cnt_addr: string,
  proof: string[]
) =>
  fetch(process.env.REACT_APP_API_URL || "http://localhost:5000", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ref,
      address,
      signature,
      cnt_addr,
      proof,
    }),
  }).then((res) => res.json());

export enum TransactionStatus {
  STATUS_QUEUED,
  STATUS_VER_SIGN,
  STATUS_TOKEN_TBA,
  STATUS_TNX_PENDING,
  STATUS_EXECUTED,
  STATUS_FAILED,
  STATUS_FAILED_SIGN_VER,
  STATUS_TOKEN_ASSIGNED,
}

export function useMintAndWait({
  ref,
  address,
  signature,
  cnt_addr,
  proof,
}: {
  ref?: string;
  address?: string;
  signature?: string;
  cnt_addr?: string;
  proof?: string[];
}) {
  const [status, setStatus] = useState<number>();

  const _internalApiCall = async () => {
    if (!ref || !address || !signature || !cnt_addr || !proof) return;

    const res = await mint(ref, address, signature, cnt_addr, proof);
    setStatus(res.status);
  };

  const isLoading = useMemo(
    () =>
      typeof status === "number" && status < TransactionStatus.STATUS_EXECUTED,
    [status]
  );

  console.log("status from", status);

  useInterval(_internalApiCall, isLoading ? 2000 : null);

  const mintAndWait = async () => _internalApiCall();

  return {
    status,
    mintAndWait,
    isLoading,
  };
}
