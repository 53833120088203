import { Wallet } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAccount, useConnect } from "wagmi";
import { Section } from "../components/layout/Section";
import { Loader } from "../components/Loader";
import { Tab } from "../components/Tab";
import { useHashParams } from "../hooks/useHashParams";
import { useWallet } from "../hooks/useWallet";
import { truncateAddress } from "../utils/common";
import { computeTree, getPrefixedMessage, hashLeaf } from "../utils/drop";
import { signers } from "../mock/signers";

interface WalletPageProps {}

export const WalletPage: React.FC<WalletPageProps> = () => {
  const { wallet, isLoading } = useWallet();
  const [{ data: connectData }] = useConnect();
  const [{ data: accountData }, _disconnect] = useAccount();
  const { hash, right, key } = useHashParams();
  const [selectedTab, setSelectedTab] = useState<"2048">("2048");

  const address = useMemo(
    () => wallet?.address || accountData?.address,
    [wallet?.address, accountData?.address]
  );

  // const isInAppWallet = Boolean(wallet);

  const [signature, setSignature] = useState("");
  useEffect(() => {
    if (!address || !key) return;

    async function sign(wallet: Wallet, address: string) {
      setSignature(await wallet.signMessage(getPrefixedMessage(address)));
    }

    sign(new Wallet(key), address);
  }, [address, key]);

  const data = useMemo(() => {
    if (!right) return;

    const tree = computeTree(signers);

    const leaf = hashLeaf(right);
    const proof = tree.getHexProof(leaf);

    return {
      root: tree.getHexRoot(),
      leaf,
      proof,
      address: right,
    };
  }, [right]);

  if (!(wallet || connectData.connected))
    return <Navigate replace to={`/${hash}`} />;

  return (
    <>
      {isLoading && <Loader />}
      <div className="flex overlay bg-opacity-60" />
      <div className="flex flex-col items-center w-full h-full text-center text-secondary">
        <Section>
          <div className="flex w-full p-2 border-2 rounded-md">
            {address && <p>{truncateAddress(address, 8)}</p>}
          </div>
        </Section>
        <Section>
          <div className="w-full tabs tabs-boxed">
            <div
              className={`flex flex-1 tab ${
                selectedTab === "2048" ? "tab-active" : ""
              }`}
              onClick={() => setSelectedTab("2048")}
            >
              Space Factory
            </div>
          </div>
        </Section>

        {selectedTab === "2048" && (
          <Tab
            active
            contractAddress={process.env.REACT_APP_2048_CONTRACT_ADDRESS || ""}
            right={right}
            address={address}
            proof={data?.proof}
            signature={signature}
            type="2048"
          />
        )}
      </div>
    </>
  );
};
