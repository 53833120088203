import { BCodeLogo, KNOBSLogo } from "../images";

interface PoweredByProps {}

export const PoweredBy: React.FC<PoweredByProps> = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full p-5">
      <div className="w-full">
        <span>Powered by</span>
      </div>
      <div className="flex items-center justify-center w-full space-x-4">
        <a href="https://knobs.it" target="_blank" rel="noopener noreferrer">
          <img src={KNOBSLogo} alt="Powered by KNOBS" />
        </a>
        <span>&</span>
        <a href="https://bcode.cloud" target="_blank" rel="noopener noreferrer">
          <img src={BCodeLogo} alt="Powered by BCode" />
        </a>
      </div>
    </div>
  );
};
