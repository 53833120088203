import { Triangle } from "react-loader-spinner";

interface LoaderProps {}

export const Loader: React.FC<LoaderProps> = () => {
  return (
    <div className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen backdrop-blur-md">
      <Triangle
        ariaLabel="loading-indicator"
        color="#fff"
        width={130}
        height={130}
      />
    </div>
  );
};
