import { arrayify, solidityPack, solidityKeccak256 } from "ethers/lib/utils";
import keccak256 from "keccak256";
import MerkleTree from "merkletreejs";

export const hashLeaf = (leaf: string) =>
  Buffer.from(solidityKeccak256(["address"], [leaf]).slice(2), "hex");

export const hashLeaves = (leaves: string[]) => leaves.map(hashLeaf);

export const computeTree = (rawLeaves: string[]) => {
  const leaves = hashLeaves(rawLeaves);

  return new MerkleTree(leaves, keccak256, { sortPairs: true });
};

const prefix = "address:";

export function getPrefixedMessage(address: string) {
  return arrayify(solidityPack(["string", "address"], [prefix, address]));
}
