interface ExternalContainerProps {
  children: React.ReactNode;
}

export const ExternalContainer: React.FC<ExternalContainerProps> = ({
  children,
}) => {
  return (
    <div className="flex flex-col flex-1 h-screen bg-violet-800">
      {children}
    </div>
  );
};
