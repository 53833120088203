import { useCallback, useState } from "react";
import { Navigate } from "react-router-dom";
import { useHash } from "react-use";
import { useConnect } from "wagmi";
import { Section } from "../components/layout/Section";
import { Loader } from "../components/Loader";
import { useWallet } from "../hooks/useWallet";
import { Metamask, WalletConnect } from "../images";

interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  const { wallet, createWallet, isLoading } = useWallet();
  const [showModal, setShowModal] = useState(false);
  const [{ data: connectData }, connect] = useConnect();
  const [hash] = useHash();

  const handleCreateWallet = useCallback(async () => {
    if (wallet) return;

    createWallet();
  }, [wallet, createWallet]);

  const handleConnect = useCallback(
    (connector: number) => {
      if (connectData.connected) return;

      connect(connectData.connectors[connector]);
    },
    [connectData.connected, connectData.connectors, connect]
  );

  if (wallet || connectData.connected)
    return <Navigate replace to={`/web3academy/wallet${hash}`} />;

  return (
    <>
      {isLoading && <Loader />}
      <div className="flex overlay bg-opacity-60" />
      <div className="flex flex-col items-center justify-center w-full h-full text-center text-secondary">
        <div className="flex flex-col items-center justify-center h-full">
          <Section>
            <h3 className="mb-5 text-3xl font-bold">
              Collega il tuo wallet e riscatta i tuoi NFT!
            </h3>
          </Section>
          <Section>
            <div className="btn btn-primary" onClick={() => handleConnect(0)}>
              <img src={Metamask} alt="metamask" className="h-8" />
            </div>
          </Section>
          <Section>
            <div className="btn btn-primary" onClick={() => handleConnect(1)}>
              <img src={WalletConnect} alt="walletconnect" className="h-8" />
            </div>
          </Section>
          {/* <Section>
            <h3 className="mb-5 text-3xl font-bold">Non hai un wallet?</h3>
            <button className="btn btn-primary" onClick={handleCreateWallet}>
              Crea
            </button>
          </Section> */}
        </div>
      </div>
    </>
  );
};
