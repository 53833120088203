// * Web3
import { chain } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { ethers } from "ethers";
import { Buffer } from "buffer";

// polyfill Buffer for client
if (!window.Buffer) {
  window.Buffer = Buffer;
}

// Chains for connectors to support
const chains = [
  process.env.REACT_APP_CHAIN === "mainnet"
    ? chain.polygonMainnet
    : chain.polygonTestnetMumbai,
];
const infuraId = process.env.REACT_APP_INFURA_KEY;

export const provider = ({ chainId }: { chainId?: number }) =>
  new ethers.providers.InfuraProvider(
    chainId,
    process.env?.REACT_APP_INFURA_KEY
  );

// Set up connectors
export const connectors = () => {
  return [
    new InjectedConnector({
      chains,
      options: { shimDisconnect: true },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        infuraId,
        qrcode: true,
      },
    }),
  ];
};

export const getAlchemyProvider = () =>
  new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_CHAIN === "mainnet"
      ? "https://polygon-mainnet.g.alchemy.com/v2/wEPCdkklg19Le37g5Jy88MwQL2VKEXGo"
      : "https://polygon-mumbai.g.alchemy.com/v2/wVP9gHNhghVns1895802v4J0REglJSdQ"
  );
