import { Wallet } from "ethers";
import { useMemo } from "react";
import { useHash } from "react-use";

export function useHashParams() {
  const [hash] = useHash();
  const key = useMemo(
    () => new URLSearchParams(hash.slice(1)).get("key"),
    [hash]
  );

  const right = useMemo(
    () => (key ? new Wallet(key).address : undefined),
    [key]
  );

  return { hash, key, right };
}
