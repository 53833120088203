import { NFTFactoryLogo } from "../../images";

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  return (
    <div className="flex items-center justify-center w-full">
      <a
        href="https://nft-factory.club"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center justify-center"
      >
        <img
          src={NFTFactoryLogo}
          alt="Powered by NFT Factory"
          className="w-1/3"
        />
      </a>
    </div>
  );
};
