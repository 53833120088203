import { PoweredBy } from "../PoweredBy";

interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
  return (
    <div className="p-2 footer bg-neutral">
      <PoweredBy />
    </div>
  );
};
